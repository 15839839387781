import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import "typeface-montserrat"
import Navigation from "./navigation"
import Footer from "./footer"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query phoneNumberQuery {
        phoneNumber: allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "home-page" } } }
        ) {
          edges {
            node {
              frontmatter {
                contact {
                  phone
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Header
          phone={data.phoneNumber.edges[0].node.frontmatter.contact.phone}
        />
        <Navigation />
        <main>{children}</main>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
