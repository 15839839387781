import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { FaPhone, FaFacebookF } from "react-icons/fa"

const HeaderWrapper = styled.header`
  background-color: #f6f6f6;
  z-index: 5;
  position: relative;

  a {
    text-decoration: none;
    color: inherit;
    transition: all 0.1s ease-in-out;
    :hover {
      color: #ee7879;
    }
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    .social-links {
      display: flex;
      justify-content: space-between;
    }
    .phone {
      display: flex;
      align-items: center;
    }
  }
`

const Header = ({ phone }) => (
  <HeaderWrapper>
    <div
      className="container header-container"
      itemScope
      itemType="http://schema.org/LocalBusiness"
    >
      <div className="sticky-menu header-row">
        <div className="phone">
          <FaPhone style={{ marginRight: "1rem" }} />
          <span>
            <a href={`tel:${phone}`} itemProp="telephone">
              {phone}
            </a>
          </span>
        </div>
        <div className="social-links">
          <span className="social-links-item">
            <a href="https://www.facebook.com/umichalowej">
              <FaFacebookF />
            </a>
          </span>
        </div>
      </div>
    </div>
  </HeaderWrapper>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
