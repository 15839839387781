import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const PreviewCompatibleImage = ({ imageInfo, style }) => {
  const imageStyle = style
  const { childImageSharp, image, imageAlt } = imageInfo

  if (!!image && !!image.childImageSharp) {
    return (
      <Img
        style={imageStyle}
        fluid={image.childImageSharp.fluid}
        alt={imageAlt}
      />
    )
  }

  if (!!childImageSharp) {
    return (
      <Img style={imageStyle} fluid={childImageSharp.fluid} alt={imageAlt} />
    )
  }

  if (!!image && typeof image === "string")
    return <img style={imageStyle} src={image} alt={imageAlt} />

  return null
}

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    imageAlt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  }).isRequired,
  style: PropTypes.object,
}

export default PreviewCompatibleImage
