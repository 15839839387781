import React from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import Img from "gatsby-image"
import { FaTimes } from "react-icons/fa"

const OverlayWrapper = styled(animated.div)`
  display: flex;
  pointer-events: ${props => (props.show ? "all" : "none")};
  align-items: center;
  justify-content: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 4rem;
  z-index: 2;
  will-change: opacity;
  button {
    cursor: pointer;
    background: none;
    color: black;
    border: 0;
    padding: 0;
    z-index: 4;
  }
  .overlay-inner {
    background: white;
    max-width: 960px;
    width: 100%;
    padding: 20px;
    z-index: 3;
    border-radius: 5px;
    will-change: transform;
  }
`

const Overlay = props => {
  const fade = useSpring({
    opacity: props.show ? 1 : 0,
  })
  const slideDown = useSpring({
    transform: props.show ? "translateY(0)" : "translateY(-100%)",
  })
  return (
    <OverlayWrapper onClick={props.click} show={props.show} style={fade}>
      <animated.div className="overlay-inner" style={slideDown}>
        <button>
          <FaTimes />
        </button>
        {props.src === null ? (
          ""
        ) : (
          <Img
            fluid={props.src}
            style={{
              borderRadius: "5px",
              maxHeight: "80vh",
              maxWidth: "920px",
            }}
            alt="#"
          />
        )}
      </animated.div>
    </OverlayWrapper>
  )
}

export default Overlay
