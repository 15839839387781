import React from "react"
import { Link as ScrollLink } from "react-scroll"
import styled from "styled-components"
import { FaFacebookF } from "react-icons/fa"

const SideDrawerContainer = styled.nav`
  background: #ee7879 none repeat scroll 0% 0%;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  opacity: ${props => (props.show ? "0.95" : "0")};
  pointer-events: ${props => (props.show ? "all" : "none")};
  transition: all 0.5s ease 0s;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;

  ul {
    text-align: right;
    padding: 0 4rem 4rem 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
  }

  li {
    margin: 0;
  }
  .side-drawer__nav-item {
    a {
      color: #fff;
      display: block;
      width: 100%;
      font-size: 2rem;
      text-decoration: none;
      line-height: 3rem;
      transition: 0.3s;
      padding: 0.3rem;
    }

    a:hover {
      color: rgba(255, 255, 255, 0.6);
    }
    .side-drawer__active {
      text-shadow: 1px 1px 10px white;
    }
  }
  .social-links {
    padding-right: 4rem;
  }
`

const SideDrawer = props => (
  <SideDrawerContainer show={props.show} onClick={props.click}>
    <ul className="side-drawer__nav-menu">
      <li className="side-drawer__nav-item">
        <ScrollLink
          to="about"
          activeClass="side-drawer__active"
          spy={true}
          smooth={true}
          offset={-20}
          duration={500}
          onClick={props.click}
        >
          o nas
        </ScrollLink>
      </li>
      <li className="side-drawer__nav-item">
        <ScrollLink
          to="rooms"
          activeClass="side-drawer__active"
          spy={true}
          smooth={true}
          offset={-10}
          duration={500}
          onClick={props.click}
        >
          pokoje
        </ScrollLink>
      </li>
      <li className="side-drawer__nav-item">
        <ScrollLink
          to="atractions"
          activeClass="side-drawer__active"
          spy={true}
          smooth={true}
          offset={-10}
          duration={500}
          onClick={props.click}
        >
          atrakcje
        </ScrollLink>
      </li>
      <li className="side-drawer__nav-item">
        <ScrollLink
          to="pricing"
          activeClass="side-drawer__active"
          spy={true}
          smooth={true}
          offset={-10}
          duration={500}
          onClick={props.click}
        >
          cennik
        </ScrollLink>
      </li>
      <li className="side-drawer__nav-item">
        <ScrollLink
          to="contact"
          activeClass="side-drawer__active"
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
          onClick={props.click}
        >
          kontakt
        </ScrollLink>
      </li>
    </ul>
    <div className="social-links">
      <span className="side-drawer__nav-item">
        <a href="https://www.facebook.com/umichalowej">
          <FaFacebookF />
        </a>
      </span>
    </div>
  </SideDrawerContainer>
)

export default SideDrawer
