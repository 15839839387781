import React from "react"

import styled from "styled-components"

const ToggleButton = styled.button`
  z-index: 1000;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  @media (max-width: 879px) {
    display: flex;
    pointer-events: auto;
  }

  :focus {
    outline: none;
  }

  span {
    width: 30px;
    height: 3px;
    background: ${props => (props.show ? "#fff" : "#1b1b1b")};
    transition: all 0.25s ease-in-out 0s;
  }

  span:nth-child(1) {
    transform: ${props =>
      props.show ? "translateY(8px) rotate(45deg)" : "none"};
  }
  span:nth-child(2) {
    transform: ${props => (props.show ? "scaleX(0)" : "none")};
    transition: transform 0.9s;
  }
  span:nth-child(3) {
    transform: ${props =>
      props.show ? "translateY(-8px) rotate(-45deg)" : "none"};
  }
`

const DrawerToggleButton = props => (
  <ToggleButton onClick={props.drawerClickHandler} show={props.show}>
    <span />
    <span />
    <span />
  </ToggleButton>
)

export default DrawerToggleButton
