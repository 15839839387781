import React, { Component } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Link as ScrollLink } from "react-scroll"
import DrawerToggleButton from "./SideDrawer/drawerToggleButton"
import SideDrawer from "./SideDrawer/sideDrawer"

const NavigationWrapper = styled.div`
  background-color: #f6f6f6;
  position: sticky;
  top: 0;
  padding: 0.3rem 0;
  z-index: 100;
  box-shadow: 0 7px 1px -5px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    :hover {
      color: #ee7879;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    .logo {
      margin: 0;
      img {
        max-width: 200px;
        margin-bottom: 0;
      }
    }

    .nav-menu {
      list-style: none;
      display: flex;
      justify-content: space-between;
      margin: 0;

      .nav-item {
        margin: 0;
        padding: 0 2rem;

        button {
          cursor: pointer;
          background: none;
          border: none;
          padding: 0;
          outline: inherit;
          transition: all 0.1s ease-in-out;
          letter-spacing: 2px;

          :focus {
            color: #ee7879;
          }

          :hover {
            color: #ee7879;
          }
        }
      }
      .nav-item:last-child {
        padding-right: 0;
      }
      @media (max-width: 880px) {
        display: none;
      }
    }
  }
  .active {
    color: #ee7879;
  }
`

const LOGO_QUERY = graphql`
  query SiteLogoQuery {
    file(name: { eq: "logo" }) {
      publicURL
    }
  }
`

class Navigation extends Component {
  state = {
    sideDrawerOpen: false,
  }

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen }
    })
  }

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false })
  }
  render() {
    let menu
    const windowPathName =
      typeof window !== "undefined" && window.location.pathname
    if (windowPathName !== "/") {
      menu = null
    } else {
      menu = (
        <>
          <nav>
            <ul className="nav-menu">
              <li className="nav-item">
                <ScrollLink
                  to="about"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-20}
                  duration={500}
                >
                  <button>o nas</button>
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  to="rooms"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-10}
                  duration={500}
                >
                  <button>pokoje</button>
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  to="atractions"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-10}
                  duration={500}
                >
                  <button>atrakcje</button>
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  to="pricing"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-10}
                  duration={500}
                >
                  <button>cennik</button>
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  to="contact"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                >
                  <button>kontakt</button>
                </ScrollLink>
              </li>
            </ul>
          </nav>
          <DrawerToggleButton
            drawerClickHandler={this.drawerToggleClickHandler}
            show={this.state.sideDrawerOpen}
          />
        </>
      )
    }
    return (
      <StaticQuery
        query={LOGO_QUERY}
        render={data => (
          <NavigationWrapper>
            <div
              className="container"
              itemScope
              itemType="http://schema.org/LocalBusiness"
            >
              <div className="row">
                <h1 className="logo" itemProp="name">
                  <Link to="/">
                    <img src={data.file.publicURL} alt="logo - u Michałowej" />
                  </Link>
                </h1>
                {menu}
              </div>
            </div>
            <SideDrawer
              show={this.state.sideDrawerOpen}
              click={this.backdropClickHandler}
            />
          </NavigationWrapper>
        )}
      />
    )
  }
}

export default Navigation
