import React from "react"
import styled from "styled-components"

import { FaFacebookF } from "react-icons/fa"
import wTechLogo from "../images/w-tech.png"

const FooterWrapper = styled.footer`
  background-color: #f6f6f6;
  .footer-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-top: 2rem;
    padding-bottom: 2rem;
    p {
      margin: 0;
    }

    .built {
      display: flex;
      align-items: center;

      a {
        margin-left: 0.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        max-height: 2rem;
      }
    }

    .author {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a {
      text-decoration: none;
      color: inherit;
      transition: all 0.1s ease-in-out;
      :hover {
        color: #ee7879;
      }
    }
    .social-links {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
`

const Footer = () => (
  <FooterWrapper>
    <div className="container footer-container">
      <div className="built">
        Built by
        <a href="https://www.weyer.tech">
          <img src={wTechLogo} alt="Logo weyer.tech" />
        </a>
      </div>
      <div className="author">
        <p style={{ textAlign: "center" }}>
          © {new Date().getFullYear()} - U Michałowej
        </p>
      </div>
      <div className="social-links">
        <span className="social-links-item">
          <a href="https://www.facebook.com/umichalowej">
            <FaFacebookF />
          </a>
        </span>
      </div>
    </div>
  </FooterWrapper>
)

export default Footer
